import { dev } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://13502ddb839aa1c693d9b0eb8ac6ff22@o4506351076376576.ingest.sentry.io/4506351078735872',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  enabled: !dev,
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry();
